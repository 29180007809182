.App {
  margin: 0 auto;
  max-width: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-nav {
  margin-bottom: 5vh;
  margin-top: 5vh;
}

.App-nav ul {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
}
